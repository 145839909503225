import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SharedStatusService {
    constructor() {}

    getAssetStatus(key: string) {
        switch (key) {
            // case 'IncompleteInformation':
            //     return {
            //         label: 'INCOMPLETE_INFORMATION',
            //         icon: '',
            //     };
            case 'Active':
                return {
                    label: 'ACTIVE',
                    icon: '',
                };
            case 'Inactive':
                return {
                    label: 'INACTIVE',
                    icon: '',
                };
            case 'OutOfService':
                return {
                    label: 'OUT_OF_SERVICE',
                    icon: '',
                };
            default:
                return {
                    label: '',
                    icon: '',
                };
        }
    }
}
